.ne-page-header {
    padding-bottom: 30px;
}

.ne-page-header h1 {
    margin-bottom: 0;
    font-weight: 600;
    font-size: 24px;
    margin-top: 3px;
    color: #fff;
}

.ne-breadcrumb {
    font-size: 12px;
    font-weight: 100;
}

.mb--2 {
    margin-bottom: -1rem;
}

@media(max-width:767px) {
    .ne-breadcrumb {
        display: none;
    }
}